.Toolbar {
  position: relative;
  top: 0;
  left: 0;
  background-color: #001529;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 8.5em;
  align-items: center;
  height: 64px;
}

.Block {
  align-items: center;
  display: flex;
}

.CurrentPage{
  margin: 9px;
}


.Title{
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
  color: #00B985;
  height: 100%;
  margin: auto 16px;
}