.login-page {
  min-height: 693px;
  position: relative;
  background: #fff;
  height: calc(100vh - 64px);
  // background-image: url('../image/Group_2195.png');
  // background-position: right;
  // background-repeat: no-repeat;
  // background-size: 50%;
  // width: 90% !important;
  .welcome {
    position: absolute;
    left: 8.47%;
    top: calc(27vh - 64px);
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: #797a80;
  }
  .title {
    position: absolute;
    text-align: left;
    left: 8.47%;
    top: calc(33vh - 64px);
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #000000;
    width: 34%;
  }
  .login-failed {
    text-align: left;
    position: absolute;
    left: 8.47%;
    top: calc(58vh - 64px);
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    z-index: 10;
  }
  .btn-login {
    position: absolute;
    left: 8.47%;
    top: calc(62vh);
    background: #00a273;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 6px 20px;
    width: 265px;
  }

  .btn-logout {
    font-size: 14px;
    line-height: 18px;
    height: 32px;
    width: 260px;
    background: #00a273;
    margin-top: 20px;
  }

  button {
    border: none;
  }

  button:active {
    background-color: #00A273 !important;
    border: none !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
  }

  button:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
  }

  button:focus {
    outline: none !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
  }

  .first {
    position: absolute;
    width: calc(45vw);
    left: 46.47%;
    top: calc(28vh - 64px);
  }
  .second {
    position: absolute;
    width: calc(50vw);
    left: 43.47%;
    top: calc(70vh - 0.4 * 1.34 * 50vw);
  }
}

.login-modal-term {
  .modal-dialog {
    max-width: 710px !important;
  }

  .modal-content {
    height: 480px;
    width: auto !important;
  }

  .modal-header {
    align-items: center !important;
    justify-content: left !important;
    height: 55px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding-left: 45px !important;

    .modal-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: #00a273;
    }
  }
  .modal-body {
    padding: 30px 35px !important;
    font-size: 16px;
    line-height: 28px;

    .button-footer {
      text-align: end;
      button {
        margin-left: 8px;
        width: 88px;
        height: 32px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    p:first-child {
      margin-bottom: 30px;
    }
    .content-text {
      border: 1px solid #e0e0e0;
      height: 270px;
      margin-bottom: 30px;
      overflow-y: auto;
      padding: 10px;
    }
  }
}

.backdrop-login {
  display: flex !important;
  .modal-dialog {
    display: flex !important;
    width: 200px;
  }

  .modal-content {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    .spinner-container {
      display: flex;
      flex-direction: column;
      padding: 0;

      .spinner-border {
        width: auto !important;
        height: auto !important;
      }

    }
  }
}
