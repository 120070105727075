.error-boundaries-container {
  height: 100vh;
  display: flex;
  justify-content: center;

  .error-boundaries {
    margin-top: 200px;
    padding: 20px;
    width: 300px;
    height: 200px;
    background-color: #D8D8D8;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    text-align: center;

    .title {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      span {
        font-size: 20px;
      }

      img {
        height: 85%;
      }
    }
  }
}