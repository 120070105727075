.App {
  text-align: center;
  min-width: 700px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cecaca;
  border-radius: 8px;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0 !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 22px;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #F9F9F9;
  border: 1px solid #D8D8D8;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: #00a273;
  border: 1px solid transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.checkbox input:checked ~ .checkmark:after {
  display: block;
  left: 3.5px;
  top: 0px;
  width: 7px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox input:indeterminate ~ .checkmark {
  background-color: #00a273;
  border: 1px solid transparent;
}

.checkbox input:indeterminate ~ .checkmark:after {
  display: block;
  left: 2px;
  top: -3px;
  width: 11px;
  height: 12px;
  border-bottom: 2px solid #fff;
}

.table-content {
  background-color: white;
  padding: 0px 20px 0px 20px;
  min-height: calc(100vh - 350px);
  display: flex;
  flex-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.require-mark {
  color: red;
}

//styling common element
select,
input {
  height: 32px;
  border-radius: 2px;
  border: 1px solid #ccc;  
}

input {
  padding-right: 15px;
}

select:focus,
input:focus {
  outline: none;
}

.error-message {
  color: red;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
}

.disabled {
  cursor: default;
}

button:disabled {
  opacity: 0.3;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
}

.r1-1 {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}

.r1-2 {
  -ms-grid-column: 2;
  -ms-grid-row: 1;
}

.r1-3 {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
}

.r2-1 {
  -ms-grid-column: 1;
  -ms-grid-row: 2;
}

.r2-2 {
  -ms-grid-column: 2;
  -ms-grid-row: 2;
}

.r2-3 {
  -ms-grid-column: 3;
  -ms-grid-row: 2;
}

.r3-1 {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}

.r3-2 {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
}

.r4-1 {
  -ms-grid-row: 4;
}

.r5-1 {
  -ms-grid-row: 5;
}

.span3 {
  -ms-grid-column-span: 3;
}

.span2 {
  -ms-grid-column-span: 2;
}
.tooltip-inner {
  max-width: 550px !important;
}