.btn{
    width: 88px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 18px;
    height: 2rem;
}

.btn-success, .btn-outline-secondary, .btn-warning, .btn-outline-warning{
    height: 2rem;
    padding-top: 0;
    padding-bottom: 0;
}

.btn-success {
    background-color: #00A273 !important;
    border: none !important;
}

button.btn-success:active {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25) !important;
  }

  button.btn-success:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }

  button.btn-success:focus {
    outline: none;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }


.btn-outline-secondary{
    border: 1px solid #222929;
    color: #38393B;
}

.btn-outline-success{
    border: 1px solid #00A273;
    color: #00A273
}

.btn-outline-success:hover{
    background-color: #00A273 ;
}

.btn-outline-success:disabled:hover{
    background-color: #fff ;
}

.btn-warning {
    background-color: #FAAD14 !important;
    border: none !important;
    color: #fff;
}

.btn-outline-warning{
    border: 1px solid #FAAD14;
    color: #38393B;
}
