.UserProfile {
  display: flex;
  align-items: center;

  .show .Name {
    color: #00A273;
    border-bottom: 1px solid #00A273;;
  }

  .UserInfo {
    display: flex;
    background: #001529 !important;
    align-items: center;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    height: auto;
    flex-flow: row wrap;

  }
  .UserInfo:active {
    background: #001529 !important;
    box-shadow: none !important;
  }
  .UserInfo::after {
    display: none !important;
  }

  .UserInfoDetail {
    background: #001529 !important;
    font-size: 10px;
    line-height: 18px;
    width: 235px;
    color: #c4c4c4;
    a,
    button {
      // background: #000 !important;
      color: #c4c4c4;
      opacity: 1 !important;
      padding: 0.25rem 16px;
    }
    a:hover,
    button:hover {
      background-color: #001529;
    }
    a:active,
    button:active {
      background-color: #001529;
    }
    a:focus,
    button:focus {
      background-color: #001529;
    }
    img {
      margin-right: 10px;
    }

    .dropdown-item-text {
      padding: 0.25rem 16px;

      .collapse, .collapsing {
        padding: 0 20px;
      }

      p {
        color: #c4c4c4;
        opacity: 1 !important;
        font-size: 10px;
        margin-bottom: 0px;
      }
    }

    span {
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      word-break: break-all;
      white-space: normal;
    }

    
  }
}
.UserIcon {
  background-color: white;
  color: #00a273;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  margin: auto 9px auto 0;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Name {
  text-align: left;
  width: 96px;
  max-width: 96px;
  margin-right: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
}
