.spinner-border {
  border-right-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  animation: spinner-border 1s linear infinite;
  width: auto;
  height: auto;
}

.spinner-container{
  width: 100%;
  position: relative;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 0 40%;
}

.loading {
  // position: absolute;
  // top: 70px;
  margin-top: 10px;
}