.menu {
  .btn-transparent {
    font-size: 1em;
    background-color: transparent;
    color: white;
    outline: none;
    width: 160px;
    text-align: left;
    box-sizing: border-box;
    border: none;
  }

  .btn-transparent:hover,
  .btn-transparent:focus {
    color: #f1f1f1;
    box-shadow: none;
  }
  
  .show {
    .dropdown-toggle {
      color: #00b985;
      position: relative;
      padding-left: 16px;
    }
    .dropdown-toggle::after {
      position: absolute;
      left: 80%;
      top: 40%;
      font-size: 14px;
    }
    .dropdown-toggle::before {
      content: "";
      position: absolute;
      width: 79.5px;
      height: 1px;
      bottom: 5px;
      left: 15px;
      background-color: #00B985;
  }
  }
  .dropdown-menu {
    background-color: #001529;
  }

  .dropdown-item:active,
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #00a273;
    a {
      color: white;
    }
    border: 0;
  }

  .dropdown-item {
    padding: 5px 11px 5px 11px;
    a {
      // display: block;
      width: 100%;
      padding: 9px 5px;
      color: #f1f1f1;
      font-size: 12px;
    }
    background-color: #001529;
  }

  .show.dropdown * {
    z-index: 999;
  }
}
