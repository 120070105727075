.language-config {
  img {
    vertical-align: middle;
  }
  .dropdown-item {
    color: white;
    font-size: 12px;
    padding: 15px;
    background-color: #001529;
  }

  .dropdown-item:active,
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #00a273;
    a {
      color: white;
    }
  }

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 15px 14px;
    img {
      width: 15px;
      height: 15px;
    }
    .language-text {
      font-size: 14px;
      color: #00b985;
      position: relative;
    }
    .language-text::after {
      content: '';
      position: absolute;
      width: 79.5px;
      height: 1px;
      bottom: -1px;
      left: 7px;
      background-color: #00b985;
    }
  }

  .dropdown-toggle::before {
    display: none;
  }

  .dropdown-menu {
    top: -2px !important;
    left: -8px !important;
    background-color: #001529;
  }
  button:focus{
      outline:none;
  }
}

.language-config:hover {
  cursor: pointer;
  box-shadow: none;
}
