.Breadcumb{
    height: 4rem;
    background-color: white;
    padding: 10px 8.5em;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    align-items: center;
}
.Tabs {
    padding-left: 0;
    list-style: none;
    display: flex;
    margin-bottom: 0;
    div{        
        border-left: 1px solid rgba(0,0,0,.1);
        li{
            margin: 0 40px;
        }
    }
    div:first-child {
        border-left: none;
    }
}
.FirstTab {
    margin-left: 0;
}